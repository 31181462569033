import template from './index.html';
import * as comp from 'comparator';

export default function Setup(inquiryType, payers, routinesByPayerUuid) {
  const container = document.createElement("div");
  container.classList.add("column");
  container.classList.add("is-one-third");

  const payerNamesAndRoutineUuids = [];
  payers.sort(comp.ascending(obj => obj.getName())).forEach((payer) => {
    const routine = routinesByPayerUuid[payer.getUuid()];
    payerNamesAndRoutineUuids.push({
      routine_uuid: routine.getUuid(),
      payer_name: payer.getName()
    });
  });

  container.innerHTML = template({
    payerNamesAndRoutineUuids: payerNamesAndRoutineUuids,
    inquiry_type: inquiryType
  });

  container.querySelectorAll("a").forEach((link) => {
    link.addEventListener("click", (ev) => {
      ev.preventDefault();

      const routineUuid = link.getAttribute('data-routine-uuid');
      let el = document.querySelector(`#routine-${routineUuid}`);
      window.scrollBy({top: el.offsetTop - document.body.scrollTop - 68});
    });
  });

  return container;
};
