import jwtDecode from 'jwt-decode';
import { getLogin } from 'api/tmyUsersV1';

const localStorageKey = 'authToken;'

export function set(authToken) {
  if (authToken) {
    window.localStorage.setItem(localStorageKey, authToken);
  } else {
    window.localStorage.removeItem(localStorageKey);
  }
}

export function getAndThrowIfExpired() {
  const authToken = window.localStorage.getItem(localStorageKey);
  if (authToken) {
    const claims = jwtDecode(authToken)
    const iss = claims.iss;
    const exp = claims.exp;
    const is_renewable = claims.tmy_renewable
    if (exp !== undefined && (exp * 1000) < Date.now()) {
      throw 'Login expired';
    } else if (is_renewable !== undefined && is_renewable && exp !== undefined && (1000*((exp-iss)/2)) < Date.now()) {
      // Token is renewable, and it's time to renew it
      getLogin()
        .then((response) => {
          set(response.getAuthToken());
        })
        .catch((err) => {
          console.log('Could not refresh token: ', err);
        });
    }
    return authToken;
  }
}

export function get() {
  try {
    return getAndThrowIfExpired();
  } catch(err) {
    return null;
  }
}

export function isAdmin() {
  const token = get();
  if (!token) {
    return false;
  }
  const claims = jwtDecode(token);
  const is_admin = claims.tmy_is_admin;
  return is_admin !== undefined && is_admin;
}