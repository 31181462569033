export default function Setup(title, type, action) {
  if (type !== "success" && type !== "info" && type !== "half-primary" && type !== "primary") {
    throw "Unknown type";
  }

  const button = document.createElement("a");
  button.className = `button is-fullwidth is-medium is-outlined is-${type}`;
  button.style.margin = '0.5rem auto';
  button.addEventListener("click", action);
  button.innerText = title;

  return button;
}
