import template from './index.html';
import localize from 'localize';

import * as comp from 'comparator';

const strings = {
  en: {
    title: "Routines for:",
    routines: "Routines",
    invoicing: "Invoicing",
  },
  sv: {
    title: "Rutiner för:",
    routines: "Rutiner",
    invoicing: "Fakturering",
  }
};

const LICENSE_PORTAL_URL = "https://licens.tummylab.se"

export default function Setup(routine, routineNumber) {
  const container = document.createElement("div");
  container.id = `routine-${routine.getUuid()}`;
  container.classList.add("box");

  container.innerHTML = template({
    t: localize(strings),
    num: routineNumber,
  });

  const payersContainer = container.querySelector(".routine-payers-container");
  
  routine.getAppliesToPayersList().sort(comp.ascending(payer => payer.getName())).forEach((payer) => {
    const item = document.createElement("div");
    item.className = "level-item";
    item.appendChild(getViewForPayer(payer));
    payersContainer.appendChild(item);
  });

  const routineItemList = container.querySelector(".routine-item-list");
  populateRoutines(routine, routineItemList);
  const invoicingItemList = container.querySelector(".invoicing-item-list");
  populateInvoicing(routine, invoicingItemList);

  return container;
}

function populateInvoicing(routine, list) {
  if (routine.getHasInvoicingViaClinic()) {
    list.appendChild(listItemWithText(localize({
      sv: `Fakturering för Tummy Lab sker mellan er och betalaren. Enligt överenskommelse kostar ett Tummy Lab-program ${routine.getProductPriceForFinalPayer()} SEK`,
      en: `You will invoice the payer for Tummy Lab. The Tummy Lab program costs ${routine.getProductPriceForFinalPayer()} SEK`
    })));
    list.appendChild(listItemWithText(localize({
      sv: "Tummy Lab AB fakturerar er för varje vidaresålt program",
      en: "Tummy Lab AB charges you for each activated program",
    })));
  } else {
    list.appendChild(listItemWithText(localize({
      sv: "Fakturering av Tummy Lab-programmet sköts direkt mellan Tummy Lab AB och betalaren",
      en: "Tummy Lab is directly invoicing the payer"
    })));
  }
}

function populateRoutines(routine, list) {
  if (routine.getRequiresInquiryBeforeOrder()) {
    let text = localize({
      sv: `Skicka ${routine.getInquiryType().toLowerCase()} om Tummy Lab-programmet`,
      en: `Send a ${routine.getInquiryType().toLowerCase()} for the Tummy Lab program`
    });
    if (routine.getHasRevisit()) {
      text += localize({
        sv: " och ett återbesök till läkare",
        en: " and a revisit to the doctor"
      });
    }
    list.appendChild(listItemWithText(text));

    if (routine.getRequiresInquiryConfirmationBeforeOrder()) {
      list.appendChild(listItemWithText(localize({
        sv: "Invänta godkännande",
        en: "Await confirmation"
      })));
    } else {
      list.appendChild(listItemWithText(localize({
        sv: "Kräver inget godkännande",
        en: "No confirmation required"
      })));
    }
  } else {
    list.appendChild(listItemWithText(localize({
      sv: "Kräver ingen förfrågan",
      en: "No inquiry required"
    })));
  }
  list.appendChild(listItemWithText(localize({
    sv: `Gå in på Tummy Lab Licensportal, <a target="_blank" href="${LICENSE_PORTAL_URL}">${LICENSE_PORTAL_URL}</a>`,
    en: `Go to the Tummy Lab License Portal, <a target="_blank" href="${LICENSE_PORTAL_URL}">${LICENSE_PORTAL_URL}</a>`
  })));
  list.appendChild(listItemWithText(localize({
    sv: "Fyll i uppgifterna, beställ en aktiveringskod och skicka koden till patienten",
    en: "Fill in the form, order an activation code and send the code to the patient"
  })));
  if (routine.getHasRevisit()) {
    list.appendChild(listItemWithText(localize({
      sv: "Boka in patienten på ett återbesök till läkaren, om ca 8—9 veckor",
      en: "Book the patient for a revisit to the doctor, in about 8–9 weeks"
    })));
  }
  if (routine.getShouldInformAfterOrder() != "") {
    list.appendChild(listItemWithText(localize({
      sv: `Meddela betalaren att patienten fått Tummy Lab (${routine.getShouldInformAfterOrder()})`,
      en: `Message the payer that the patient has received Tummy Lab (${routine.getShouldInformAfterOrder()})`
    })));
  }
}

function getViewForPayer(payer) {
  const view = document.createElement("div");
  view.style.paddingLeft = "0.75rem";
  view.style.paddingRight = "0.75rem";
  if (payer.getHasLogoUrl()) {
    // Show logo
    const image = document.createElement("img");
    image.setAttribute("src", payer.getLogoUrl());
    image.setAttribute("alt", payer.getName());
    image.style.maxHeight = "3rem";
    view.appendChild(image);
  } else {
    // Show name
    const name = document.createElement("span");
    name.classList.add("has-text-weight-medium");
    name.classList.add("is-size-5");
    name.innerHTML = payer.getName();
    view.appendChild(name);
  }
  return view;
}

function listItemWithText(text) {
  const li = document.createElement("li");
  li.innerHTML = text;
  return li;
}

