import template from './index.html';

import localize from 'localize';
import * as comp from 'comparator';
import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';
import RoutineView from 'components/routine';
import PayersListContainer from 'components/payers_list_container';
import { getRoutines } from 'api/tmyPartnerWebV1';

const strings = {
  en: {
    title: "Routines for Tummy Lab",
    desc: "After investigation and ruling by your doctor that the patient has IBS and is suitable for Tummy Lab",
    no_inquiry_type: "No inquiry",
  },
  sv: {
    title: "Rutiner för Tummy Lab",
    desc: "Efter utredning och bedömning av er läkare att patienten har IBS och är lämplig för Tummy Lab",
    no_inquiry_type: "Ingen förfrågan",
  }
};

const noRoutines = {
  en: "There are no routines to show",
  sv: "Det finns inga rutiner att visa"
};

export default function Setup() {
  const container = document.createElement("div");

  const progress = document.createElement("progress");
  progress.className = "progress is-success";
  container.appendChild(progress);

  getRoutines()
  .then((routines) => {
    if (routines.length === 0) {
      // No routines
      container.appendChild(SuccessNotification(localize(noRoutines, false)));
      return container;
    }
    populateContent(container, routines);
  })
  .catch((err) => {
    const errMsg = localize({
      en: "Failed to load routines: " + err,
      sv: "Kunde inte ladda in rutinerna: " + err
    });
    container.appendChild(ErrorNotification(errMsg, false));
  })
  .finally(() => {
    progress.remove();
  });

  return container;
}

function populateContent(container, routines) {
  const localizedStrings = localize(strings);
  container.innerHTML = template({
    t: localizedStrings,
  });

  const routinesContainer = container.querySelector("#routines-container");

  const routinesByPayerUuid = {};
  const payersByInquiryType = {};

  routines.sort(comp.ascending(r => r.getAppliesToPayersList().length)).forEach((routine, idx) => {
    routinesContainer.appendChild(RoutineView(routine, idx + 1));

    routine.getAppliesToPayersList().forEach((payer) => {
      routinesByPayerUuid[payer.getUuid()] = routine;

      const inquiryType = routine.getInquiryType() != "" ? routine.getInquiryType() : localizedStrings["no_inquiry_type"];
      if (payersByInquiryType[inquiryType] === undefined) { payersByInquiryType[inquiryType] = []; }
      payersByInquiryType[inquiryType].push(payer);
    });
  });

  const payersListsContainer = container.querySelector(".payers-lists-container");
  Object.keys(payersByInquiryType).forEach((inquiryType) => {
    payersListsContainer.appendChild(PayersListContainer(
      inquiryType,
      payersByInquiryType[inquiryType],
      routinesByPayerUuid
    ));
  });
}
