import template from './index.html';
import localize from 'localize';

const strings = {
  en: {
    title: "Support",
    description: "If you need help, if there is some sort of problem, or if you're just wondering something; then you're more than welcome to contact Johanna using one of the following methods.",
    email: "Email",
    phone: "Phone number",
    description_tech: "For technical questions or problems, please use the contact information below instead.",
  },
  sv: {
    title: "Support",
    description: "Om du behöver hjälp med något, ifall något strular, eller om du bara undrar något; då är du mer än välkommen att kontakta Johanna på mail eller telefon.",
    email: "E-mail",
    phone: "Telefonnummer",
    description_tech: "Vid tekniska frågor eller problem, vänligen använd kontaktinformationen nedan istället.",
  }
};

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
  })

  return container;
}