import template from './index.html';
import menuButton from './menu_button';

import { navigateTo } from 'app';
import localize from 'localize';
import { getSupportDocuments, getRoutines } from 'api/tmyPartnerWebV1';

const strings = {
  en: {
    title: "What would you like to do?",
    documentation: "Documentation",
  },
  sv: {
    title: "Vad skulle du vilja göra?",
    documentation: "Dokumentation",
  }
};

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings)
  });

  let actionContainer = container.querySelector(".index-actions");
  actionContainer.appendChild(menuButton(localize({sv: 'Beställ en ny licens', en: 'Order a new license'}), "success", () => {
    navigateTo("order");
  }));

  actionContainer.appendChild(menuButton(localize({sv: 'Kontakta supporten', en: 'Contact support'}), "success", () => {
    navigateTo("support");
  }));

  Promise.all([getSupportDocuments(), getRoutines()])
  .then(([documents, routines]) => {
    container.querySelector(".loading-indicator").remove();
    const docContainer = container.querySelector("#documentation-container");

    if (documents.length === 0 && routines.length === 0) { return; }

    docContainer.classList.remove("is-hidden");

    if (routines.length > 0) {
      docContainer.appendChild(menuButton(localize({en: "Routines", sv: "Rutiner"}), "half-primary", () => {
        navigateTo("routines");
      }));
    }

    documents.forEach((doc) => {
      docContainer.appendChild(menuButton(doc.getTitle(), "half-primary", () => {
        window.open(doc.getUrl());
      }));
    });
  });


  return container;
}
