import template from './index.html';
import printTemplate from './print.html';
import localize from 'localize';
import { addOnNavigatedCallback } from 'app';

import ErrorNotification from 'components/notification/error';
import { navigateTo } from '../../app';
import { getOrderForm, postOrder, postSmsInvite } from 'api/tmyPartnerWebV1';
import TummyLabImage from '../../../img/tummy-lab.png';

const strings = {
  en: {
    title: "Order a new license",
    step: "Step",
    order: "Order",
    indication_for_use: "Tummy Lab shall only be used for patients with diagnosed IBS.",
    step_1: {
      title: "Fill in your patient's details",
      clinic: "Clinic",
      personnummer: "Patient's personnummer",
      personnummer_error: "The specified personnummer is not valid, must be on the form YYMMDD-XXXX",
      phone: "Patient's mobile phone number",
      phone_error: "Not a valid mobile phone number",
      phone_optional: "Only used for SMS-invites",
      payer: "Payer",
      program: "Program",
      program_error: "You must select which program to order",
      confirm_payer: "Payer has confirmed the order.",
    },
    step_2: {
      title: "Send activation code to the patient's phone",
      code: "Activation code",
      code_help: "If the patient doesn't have an available mobile phone number, let the patient write the code down.",
      send: "Send code to phone",
      print: "Print code",
      no_phone_number: "No phone number",
      sms_failed: "Failed to send SMS, please try again",
      sms_consent: "Only send code to the mobile phone if the patient has consented to SMS invites.",
    },
    step_3: {
      title: "Order is complete",
      help: "You can order another license, or you can close the page to exit.",
      on_error: "If anything went wrong, please make a note of the activation code and contact us through the 'support' tab.",
      done: "Done — go back to start",
    },
  },
  sv: {
    title: "Beställ en ny licens",
    step: "Steg",
    order: "Beställ",
    indication_for_use: "Tummy Lab skall enbart användas för patienter med fastställd IBS.",
    step_1: {
      title: "Fyll i din patients uppgifter",
      clinic: "Klinik",
      personnummer: "Patientens personnummer",
      personnummer_error: "Det angivna personnummret är inte giltigt, det måste va på formen ÅÅMMDD-XXXX",
      phone: "Patientens mobilnummer",
      phone_error: "Inte ett giltigt mobilnummer",
      phone_optional: "Används enbart för SMS-inbjudan",
      payer: "Betalare",
      program: "Program",
      program_error: "Du måste välja vilket program som ska beställas",
      confirm_payer: "Betalaren har godkänt beställningen.",
    },
    step_2: {
      title: "Skicka aktiveringskoden till patientens telefon",
      code: "Aktiveringskod",
      code_help: "Om patienten inte har ett tillgängligt mobilnummer, låt patienten skriva ner koden.",
      send: "Skicka kod till mobil",
      print: "Skriv ut kod",
      no_phone_number: "Det finns inget mobilnummer",
      sms_failed: "Misslyckades med att skicka SMS, försök igen",
      sms_consent: "Skicka endast koden till mobilen ifall patienten har gett sin tillåtelse att ta emot SMS inbjudan.",
    },
    step_3: {
      title: "Beställning är färdig",
      help: "Du kan beställa en ny licens, eller stänga sidan för att avsluta.",
      on_error: "Om något gick fel, vänligen anteckna aktiveringskoden och kontakta oss via 'support'-fliken.",
      done: "Färdig — gå tillbaka till startsidan",
    },
  }
};

// Regex from:
// http://lindbergonline.se/webb/validera-personummer/
const personnummerRegExp = new RegExp("^(19|20)?[0-9]{6}[- ]?[0-9]{4}$");

const DEAFULT_PHONE_COUNTRY_CODE = "46";
const SMS_DISABLED_DELAY = 60; // seconds

export default function Setup() {
  const container = document.createElement("div");
  const progress = document.createElement("progress");
  progress.classList.add("progress");
  progress.classList.add("is-success");
  container.appendChild(progress);

  getOrderForm()
  .then((resp) => {
    progress.remove();
    populateForm(container, resp);
  })
  .catch((err) => {
    progress.remove();
    console.log("Failed to get order form:", err);
    container.appendChild(ErrorNotification(localize({
      en: "Could not show order form, try again later or contact support.",
      sv: "Kunde inte visa beställningsformuläret, försök igen senare eller kontakta support."
    }), false))
  })
  return container;
}

function populateForm(container, form) {
  container.innerHTML = template({
    t: localize(strings),
    clinic_name: form.getClinic().getName()
  });

  // Populate the products list
  const productMapping = {};
  const productSelector = container.querySelector("#step-1-product");
  form.getSortedProductsList().forEach((product) => {
    productMapping[product.getUuid()] = product;
    const prod = document.createElement("option");
    prod.setAttribute("value", product.getUuid());
    prod.innerText = product.getName();
    productSelector.appendChild(prod);
  });
  if (form.getSortedProductsList().length <= 1) {
    productSelector.classList.add("is-disabled");
    productSelector.setAttribute("disabled", "disabled");
  }
  let selectedProducut = form.getSortedProductsList()[0];
  productSelector.addEventListener("change", (ev) => {
    selectedProducut = productMapping[ev.target.value];
  });

  // Populate the payers list
  const orderButton = container.querySelector("#step-1-order");
  const payerMapping = {};
  const payerSelector = container.querySelector("#step-1-payer");
  const payerFieldsContainer = container.querySelector("#step-1-payer-specific");
  let selectedPayer = null;
  if (form.getSortedPayersList().length <= 1 && form.getAllowHidingPayerSelectorInOrderForm()) {
    // If there is only one payer, hide the payer selector and show the payer fields
    container.querySelector("#step-1-payer-field").classList.add("is-disabled");
    container.querySelector("#step-1-payer-field").classList.add("is-hidden");
    payerFieldsContainer.classList.remove("is-hidden");
    orderButton.classList.remove("is-disabled");
    orderButton.removeAttribute("disabled");
    selectedPayer = form.getSortedPayersList()[0];
    populateFormForPayer(container, selectedPayer);
  } else {
    // If there are more than one payer, require the user to select one before
    // showing payer fields
    const pay = document.createElement("option");
    pay.setAttribute("value", "default");
    pay.innerText = localize({en: "« Select payer »", sv: "« Välj betalare »"});
    payerSelector.appendChild(pay);
    orderButton.classList.add("is-disabled");
    orderButton.setAttribute("disabled", "disabled");
  }
  form.getSortedPayersList().forEach((payer) => {
    payerMapping[payer.getUuid()] = payer;
    const pay = document.createElement("option");
    pay.setAttribute("value", payer.getUuid());
    pay.innerText = payer.getName();
    payerSelector.appendChild(pay);
  });

  payerSelector.addEventListener("change", (ev) => {
    if (ev.target.value == "default") {
      payerFieldsContainer.classList.add("is-hidden");
      selectedPayer = null;
      orderButton.classList.add("is-disabled");
      orderButton.setAttribute("disabled", "disabled");
    } else {
      payerFieldsContainer.classList.remove("is-hidden");
      orderButton.classList.remove("is-disabled");
      orderButton.removeAttribute("disabled");
      selectedPayer = payerMapping[ev.target.value];
      populateFormForPayer(container, selectedPayer);
    }
  });

  // Setup phone number formatting
  container.querySelector("#step-1-phone").addEventListener("change", (ev) => {
    const num = ev.target.value.replace(/[^+0-9]/g, "");
    if (num.length >= 2 && num[0] == "0" && num[1] == "0") {
      ev.target.value = "+" + num.slice(2);
    } else if (num.length >= 1 && num[0] == "0") {
      ev.target.value = "+" + DEAFULT_PHONE_COUNTRY_CODE + num.slice(1);
    } else {
      ev.target.value = num;
    }
  })

  let didSuccessfullyOrder = false;

  const confirmCheckBox = container.querySelector("#step-1-confirm-payer");

  container.querySelector("#step-1-confirm-payer").addEventListener("change", (ev) => {
    console.log("Changed confirmation:", ev.target.checked);
    if (ev.target.checked) {
      orderButton.classList.remove("is-disabled");
      orderButton.removeAttribute("disabled");
    } else {
      orderButton.classList.add("is-disabled");
      orderButton.setAttribute("disabled", "disabled");
    }
  });

  orderButton.addEventListener("click", (ev) => {
    if (didSuccessfullyOrder || !confirmCheckBox.checked || selectedPayer === null) {
      return;
    }
    container.querySelector("#error-container").removeAllChildren();
    if (isStepOneValid(container, selectedPayer)) {
      orderButton.classList.add("is-loading");
      disableStepOneInputs(container);

      let patientId = null;
      if (selectedPayer.getUsesPhoneNumberAsPatientId()) {
        patientId = container.querySelector("#step-1-phone").value || null;
      } else {
        patientId = container.querySelector("#step-1-patient-id").value || null;
      }

      if (patientId === null) {
        throw "Patient ID not found";
      }

      const phoneNumber = container.querySelector("#step-1-phone").value || "";

      postOrder(form.getClinic().getUuid(), selectedPayer.getUuid(), selectedProducut.getUuid(), patientId)
      .then((activationCode) => {

        // On successful API call
        didSuccessfullyOrder = true;
        orderButton.classList.remove("is-loading");
        orderButton.classList.add("is-disabled");
        orderButton.setAttribute("disabled", "disabled");
        container.querySelectorAll(".order-step").forEach((match) => {
          match.removeAttribute("disabled");
        });

        let postOrderMessage = null;
        if (selectedPayer.getHasPostOrderInformation() && selectedPayer.getPostOrderInformation() != "") {
          postOrderMessage = selectedPayer.getPostOrderInformation();
        }
        populateRemainingSteps(container, activationCode, phoneNumber, selectedProducut, postOrderMessage);
        container.querySelector("#scroll-to-target").scrollIntoView(true);
      })
      .catch((err) => {
        // On failed API call
        enableStepOneInputs(container);
        orderButton.classList.remove("is-loading");
        container.querySelector("#error-container").appendChild(ErrorNotification(err, false));
        container.querySelector("#error-container").scrollIntoView(false);
      });
    }
  });
}

function populateFormForPayer(container, payer) {
  console.log("Payer:", payer.getName());

  const orderButton = container.querySelector("#step-1-order");
  const patientField = container.querySelector("#step-1-patient-id-field");
  const phoneField = container.querySelector("#step-1-phone-field");
  const confirmBoxField = container.querySelector("#step-1-confirm-payer-field");
  const confirmBox = container.querySelector("#step-1-confirm-payer");
  const preOrderMessage = container.querySelector("#step-1-pre-order-information");

  function setConfirmBoxChecked(checked) {
    if (confirmBox.checked != checked) {
      console.log("Sending click to toggle confirm checkbox");
      confirmBox.click();
    }
  }

  // First we clear any previous values
  patientField.querySelector("input").value = "";
  patientField.querySelector("input").setAttribute("placeholder", "ååmmdd-xxxx");
  patientField.querySelector("label").innerText = localize({
    en: "Patient's personnummer",
    sv: "Patientens personnummer"
  });
  patientField.classList.remove("is-hidden");
  phoneField.querySelector("input").value = "";
  phoneField.classList.remove("is-hidden");
  setConfirmBoxChecked(true);
  confirmBoxField.classList.add("is-hidden");
  orderButton.classList.remove("is-disabled");
  orderButton.removeAttribute("disabled", "disabled");

  preOrderMessage.parentElement.classList.add("is-hidden");
  preOrderMessage.innerText = "";


  // Reset all help fields
  container.querySelector("#order-step-1").querySelectorAll("input").forEach((match) => {
    match.classList.remove("is-danger");
  });
  container.querySelector("#order-step-1").querySelectorAll(".select").forEach((match) => {
    match.classList.remove("is-danger");
  });
  container.querySelector("#order-step-1").querySelectorAll(".help").forEach((match) => {
    match.classList.add("is-hidden");
  });

  if (!payer.getUsesPhoneNumberAsPatientId()) {
    container.querySelector("#step-1-phone-optional").classList.remove("is-hidden");
  }

  // Now we see which fields to show
  if (payer.getUsesPhoneNumberAsPatientId()) {
    // If we use phone as ID, we don't need a separate patient ID field
    patientField.classList.add("is-hidden");
    container.querySelector("#step-1-phone-optional").classList.add("is-hidden");
  } else if (payer.getUsesCustomPatientId()) {
    // Customise the payer id field
    patientField.querySelector("input").removeAttribute("placeholder");
    if (payer.getCustomPatientIdTitle() == null || payer.getCustomPatientIdTitle() == "") {
      patientField.querySelector("label").innerText = "Patient ID";
    } else {
      patientField.querySelector("label").innerText = payer.getCustomPatientIdTitle();
    }
  }

  if (payer.getRequiresConfirmationBeforeOrder()) {
    setConfirmBoxChecked(false);
    orderButton.classList.add("is-disabled");
    orderButton.setAttribute("disabled", "disabled");
    confirmBoxField.classList.remove("is-hidden");
  }

  if (payer.getHasPreOrderInformation() && payer.getPreOrderInformation() != "") {
    preOrderMessage.innerText = payer.getPreOrderInformation();
    preOrderMessage.parentElement.classList.remove("is-hidden");
  }
}

function isStepOneValid(container, payer) {
  console.log("Validating step 1 form values");

  // Reset all help fields
  container.querySelector("#order-step-1").querySelectorAll("input").forEach((match) => {
    match.classList.remove("is-danger");
  });
  container.querySelector("#order-step-1").querySelectorAll(".select").forEach((match) => {
    match.classList.remove("is-danger");
  });
  container.querySelector("#order-step-1").querySelectorAll(".help").forEach((match) => {
    match.classList.add("is-hidden");
  });

  const patientId = container.querySelector("#step-1-patient-id").value || "";
  const phoneNumber = container.querySelector("#step-1-phone").value || "";

  let allStepsValid = true;

  if (payer.getUsesPhoneNumberAsPatientId()) {
    // We only need to make sure there is a phone number
    if (!isPhoneNumberValid(phoneNumber)) {
      console.log("Phone number is invalid");
      container.querySelector("#step-1-phone-field").querySelector(".help").classList.remove("is-hidden");
      container.querySelector("#step-1-phone").classList.add("is-danger");
      allStepsValid = false;
    }

  } else {
    // Phone number is optional, so empty phone number is OK,
    // but not an invalid one.
    if (phoneNumber.trim() != "" && !isPhoneNumberValid(phoneNumber)) {
      console.log("Phone number is invalid");
      container.querySelector("#step-1-phone-field").querySelector(".help").classList.remove("is-hidden");
      container.querySelector("#step-1-phone").classList.add("is-danger");
      allStepsValid = false;
    }

    if (payer.getUsesCustomPatientId()) {
      // We need to verify the custom patient ID
      let idRegex = new RegExp("^.+$");
      if (payer.getCustomPatientIdRegex() != null && payer.getCustomPatientIdRegex() != "") {
        idRegex = new RegExp(payer.getCustomPatientIdRegex());
      }

      if (!idRegex.test(patientId)) {
        console.log("Patient ID is invalid");
        const helpText = container.querySelector("#step-1-patient-id-field").querySelector(".help");
        helpText.innerText = localize({
          en: "The entered value is not valid",
          sv: "Det angivna värdet är inte giltigt"
        });
        helpText.classList.remove("is-hidden");
        container.querySelector("#step-1-patient-id").classList.add("is-danger");
        allStepsValid = false;
      }
    } else {
      // Personnummer must be valid, and phonenumber is optional
      if (!isPersonnummerValid(patientId)) {
        console.log("Personnummer is invalid");
        const helpText = container.querySelector("#step-1-patient-id-field").querySelector(".help");
        helpText.innerText = localize({
          en: "The specified personnummer is not valid, must be on the form YYMMDD-XXXX",
          sv: "Det angivna personnummret är inte giltigt, det måste va på formen YYMMDD-XXXX"
        });
        helpText.classList.remove("is-hidden");
        container.querySelector("#step-1-patient-id").classList.add("is-danger");
        allStepsValid = false;
      }
    }
  }

  return allStepsValid;
}

function isPersonnummerValid(input) {
  if (input.trim() == "") {
    return false;
  }
  return personnummerRegExp.test(input);
}

function isPhoneNumberValid(input) {
  if (input.trim() == "") {
    return false;
  }
  const trimmed = input.replace(/ /g,'');
  if (trimmed.length < 6) {
    return false;
  }
  const num = Number(trimmed);
  if (isNaN(num)) {
    return false;
  }
  return Number.isInteger(num);
}

function populateRemainingSteps(container, activationCode, phoneNumber, selectedProduct, postOrderMessage = null) {
  let allowLeave = false;
  if (typeof window.addEventListener === 'undefined') {
    window.addEventListener = function(e, callback) {
      return window.attachEvent('on' + e, callback);
    }
  }

  window.addEventListener('beforeunload', (ev) => {
    if (!allowLeave) {
      ev.returnValue = localize({
        en: "Are you sure you want to leave?",
        sv: "Är du säker på att du vill lämna?",
      });
    }
  });

  addOnNavigatedCallback((page) => {
    return allowLeave || confirm(localize({
      en: "Are you sure you want to leave the page?",
      sv: "Är du säker på att du vill lämna sidan?",
    }));
  });


  const codeField = container.querySelector("#step-2-code");
  codeField.value = activationCode;
  const sendButton = container.querySelector("#step-2-send");
  if (phoneNumber != "" && phoneNumber != null && phoneNumber !== undefined) {
    let buttonDisabled = false;
    sendButton.addEventListener("click", (ev) => {
      if (buttonDisabled) {
        return;
      }
      console.log("Send code as SMS");

      container.querySelector("#step-2-sms-failed").classList.add("is-hidden");
      sendButton.classList.add("is-loading");
      sendButton.setAttribute("disabled", "disabled");
      buttonDisabled = true;
      postSmsInvite(activationCode, phoneNumber)
      .then(() => {
        console.log("SMS successfully scheduled");
        codeField.classList.remove("has-background-primary");
        codeField.classList.add("has-background-green");
        sendButton.classList.add("is-disabled");
        sendButton.classList.remove("is-loading");
        sendButton.text = localize({
          en: "The code is sent!",
          sv: "Koden är skickad!",
        });
        sleep(SMS_DISABLED_DELAY * 1000).then(() => {
          // Enable send button again after some time
          sendButton.classList.remove("is-loading");
          sendButton.classList.remove("is-disabled");
          sendButton.removeAttribute("disabled");
          buttonDisabled = false;
          sendButton.text = localize({
            en: "Send code again",
            sv: "Skicka kod igen",
          });
        });
      })
      .catch((err) => {
        console.log("Failed to send SMS invite request:", err);
        sendButton.classList.remove("is-loading");
        sendButton.classList.remove("is-disabled");
        sendButton.removeAttribute("disabled");
        buttonDisabled = false;
        container.querySelector("#step-2-sms-failed").classList.remove("is-hidden");
      });
    });
  } else {
    // No phone number
    sendButton.classList.add("is-disabled");
    sendButton.classList.add("is-hidden");
    sendButton.setAttribute("disabled", "disabled");
    container.querySelector("#step-2-sms-consent").classList.add("is-hidden");
    container.querySelector("#step-2-code-help").classList.add("is-hidden");
    // Change title
    container.querySelector("#step-2-title").innerText = localize({
      en: "Print activation code",
      sv: "Skriv ut aktiveringskod",
    });
  }

  container.querySelector("#step-2-print").addEventListener("click", (ev) => {
    console.log("Print patient's code");
    printActivationCode(activationCode, selectedProduct.getName());
  });

  container.querySelector("#step-3-done").addEventListener("click", (ev) => {
    console.log("Order complete");
    allowLeave = true;
    navigateTo("index");
  });

  if (postOrderMessage != null) {
    const postOrderMessageField = container.querySelector("#step-3-post-order-information");
    postOrderMessageField.innerText = postOrderMessage;
    postOrderMessageField.parentElement.classList.remove("is-hidden");
  }
}

function disableStepOneInputs(container) {
  container.querySelector("#order-step-1").querySelectorAll("input").forEach((match) => {
    match.setAttribute("disabled", "disabled");
    match.classList.add("is-disabled");
  });
  container.querySelector("#order-step-1").querySelectorAll("select").forEach((match) => {
    match.setAttribute("disabled", "disabled");
    match.classList.add("is-disabled");
  });
}

function enableStepOneInputs(container) {
  container.querySelector("#order-step-1").querySelectorAll("input").forEach((match) => {
    match.removeAttribute("disabled", "disabled");
    match.classList.remove("is-disabled");
  });
  container.querySelector("#order-step-1").querySelectorAll("select").forEach((match) => {
    match.removeAttribute("disabled", "disabled");
    match.classList.remove("is-disabled");
  });
}

function printActivationCode(activationCode, productName) {
  const winPrint = window.open('', '_blank');
  winPrint.document.write(printTemplate({
    product_name: productName,
    code: activationCode,
    logo_url: TummyLabImage,
  }));
  winPrint.document.close();
  winPrint.focus();
  sleep(100).then(() => {
    winPrint.print();
    winPrint.close();
  });
}
