import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';

import '@fortawesome/fontawesome-free/js/all.js';
import './index.scss';

import './extensions.js';
import './polyfills.js';

import * as authToken from 'authToken';
import localize from 'localize';

import PageAuthenticate from 'pages/authenticate';
import PageLogin from 'pages/login';
import PageIndex from 'pages/index';
import PageOrder from 'pages/order';
import PageSupport from 'pages/support';
import PageRoutines from 'pages/routines';

import ErrorNotification from 'components/notification/error';

export function navigateTo(page, extra = {}) {
  const container = document.querySelector(".page-container");
  refreshContent(container, page, false, extra);
}

let onNavigatedCallbacks = [];
export function addOnNavigatedCallback(callback) {
  onNavigatedCallbacks.push(callback);
}

function refreshContent(container, page, replaceState = false, extra = {}) {
  let shouldAbort = false;
  onNavigatedCallbacks.forEach((callback) => {
    if (!callback(page)) {
      shouldAbort = true;
    }
  });
  if (shouldAbort) {
    console.log("Aborted page navigation to page:", page);
    return;
  }
  onNavigatedCallbacks = [];

  document.querySelectorAll(".navbar-item").forEach((match) => { match.classList.remove("has-text-weight-medium") });
  try {
    const element = document.querySelector("#page-" + page);
    if (element) { element.classList.add("has-text-weight-medium"); }
  } catch (err) {
    console.log("Navbar item for page", page, "not found:", err);
  }

  if (replaceState) {
    window.history.replaceState({page: page}, document.title, document.location);
  } else {
    var url = "?page=" + page;
    Object.keys(extra).forEach((key) => {
      url += "&" + key + "=" + extra[key];
    });
    window.history.pushState({page: page}, document.title, url);
  }

  container.removeAllChildren();

  const logoutMenuItem = document.querySelector("#page-_logout");
  if (!authToken.get()) {
    logoutMenuItem.classList.add("is-hidden");
  } else {
    logoutMenuItem.classList.remove("is-hidden");
  }

  let currentAuthToken = null;
  try {
    currentAuthToken = authToken.getAndThrowIfExpired();
  } catch(err) {
    authToken.set(null);
    container.replaceChildren(ErrorNotification(err, true));
  }

  // No need to be logged in to view the "authenticate" or "support" pages
  if (!currentAuthToken && page != "authenticate" && page != "support") {
    container.replaceChildren(PageLogin((newAuthToken) => {
      authToken.set(newAuthToken);
      refreshContent(container, page, true);
    }));

    return;
  }

  switch (page) {
    case "order":
      container.replaceChildren(PageOrder());
      break;
    case "support":
      container.replaceChildren(PageSupport());
      break;
    case "authenticate":
      container.replaceChildren(PageAuthenticate());
      break;
    case "routines":
      container.replaceChildren(PageRoutines());
      break;
    case "index":
    case "start":
    default:
      container.replaceChildren(PageIndex());
      break;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const startmenuItem = document.querySelector("#page-start");
  startmenuItem.innerText = localize({sv: "Start", en: "Start"});

  const orderMenuItem = document.querySelector("#page-order");
  orderMenuItem.innerText = localize({sv: "Beställ en ny licens", en: "Order a new license"});

  const supportMenuItem = document.querySelector("#page-support");
  supportMenuItem.innerText = localize({sv: "Support", en: "Support"});

  const logoutMenuItem       = document.querySelector("#page-_logout");
  logoutMenuItem.innerText = localize({sv: 'Logga ut', en: 'Logout'})

  document.querySelector("#navigation-title").innerText = localize({sv: "Licensportal", en: "License portal"}) + " | Tummy Lab";
  document.querySelector("title").innerText = localize({sv: "Licensportal", en: "License portal"}) + " | Tummy Lab";

  const initialPage = require('querystring').parse(window.location.search.substring(1)).page || "index";
  const container = document.querySelector(".page-container");
  refreshContent(container, initialPage, true);

  window.addEventListener("popstate", (ev) => {
    refreshContent(container, event.state.page, true);
  });

  document.querySelectorAll('.main-navigation .navbar-item').forEach((el) => {
    el.addEventListener("click", (ev) => {
      ev.preventDefault();

      const page = el.id.substring(5); // Removes the 'page-' part of the ID

      if (page === "_logout") {
        authToken.set(null);
        refreshContent(container, "index");
      } else {
        refreshContent(container, page);
      }
    });
  });

  const navbarBurger = document.querySelector('.navbar-burger');
  navbarBurger.addEventListener('click', function () {
    navbarBurger
      .classList
      .toggle('is-active');
    document
      .getElementById("navMenu")
      .classList
      .toggle('is-active');
  });
});
