import template from './index.html';

import localize from 'localize';

import * as authToken from 'authToken';

import { navigateTo } from 'app';
import { loginPlatformToken } from 'config';
import { postLogin } from 'api/tmyUsersV1';

import ErrorNotification from 'components/notification/error';

import PageIndex from 'pages/index';

const strings = {
  en: {
    title: "Authentication",
  },
  sv: {
    title: "Inloggning"
  }
};

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({t: localize(strings)});

  const progressBox = container.querySelector(".box");
  const errorContainer = container.querySelector(".error-container");


  const loginToken = require('querystring').parse(window.location.search.substring(1)).token || null;
  if (loginToken === null) {
    console.log("No login token specified");
    errorContainer.appendChild(ErrorNotification(localize({en: "No login token specified", sv: "Ingen inloggningstoken angiven"})));
    progressBox.remove();
    return container;
  }

  try {
    const loginDetails = JSON.parse(atob(loginToken));
    const username = loginDetails["username"] || null;
    const password = loginDetails["password"] || null;

    if (username === null || password === null) {
      console.log("Missing username or password");
      throw "Missing data";
    }

    postLogin(username, password, loginPlatformToken).then((res) => {
      console.log("Successful login");
      authToken.set(res.getAuthToken());

      // We directly insert the page instead of navigating to it, this
      // allows us to preserve the authentication URL since people will
      // want to add it as a bookmark for easy logins.
      if (container.parentNode) {
        container.parentNode.insertBefore(PageIndex(), container);
        container.parentNode.removeChild(container);
      } else {
        // In case the request finishes before the page is inserted into
        // a parent container.
        container.replaceChildren(PageIndex());
      }
    })
    .catch((err) => {
      console.log("Login failed:", err);
      errorContainer.appendChild(ErrorNotification(localize({en: "Token is no longer valid", sv: "Länken är inte giltig längre"})));
      progressBox.remove();
      return container;
    });

  } catch {
    console.log("Invalid login token");
    errorContainer.appendChild(ErrorNotification(localize({en: "Malformed login token", sv: "Inloggningstoken har dåligt format"})));
    progressBox.remove();
    return container;
  }

  return container;
}