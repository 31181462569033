import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.partner_web.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.partner_web.v1/requests_pb';

const API_NAME = "tmy.partner_web.v1";

export function getSupportDocuments() {
  return tmy.get("/support_documents", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.PartnerWeb.V1.Responses.GetSupportDocuments", responses.GetSupportDocuments))
    .then((resp) => {
      return resp.getSortedSupportDocumentsList();
    })
}

export function getOrderForm() {
  return tmy.get("/order_form", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.PartnerWeb.V1.Responses.GetOrderForm", responses.GetOrderForm))
}

export function postOrder(clinic_uuid, payer_uuid, product_uuid, patient_id) {
  const req = new requests.PostOrder();
  req.setClinicUuid(clinic_uuid);
  req.setPayerUuid(payer_uuid);
  req.setProductUuid(product_uuid);
  req.setPatientIdentification(patient_id);

  return tmy.post("/orders/", req.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.PartnerWeb.V1.Requests.PostOrder"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.PartnerWeb.V1.Responses.PostOrder", responses.PostOrder))
    .then((resp) => {
      return resp.getActivationCode();
    })
}

export function postSmsInvite(activationCode, phoneNumber) {
  console.log("Sending SMS invite to", phoneNumber);
  const req = new requests.PostUserActivationCodeSmsInvite();
  req.setPhoneNumber(phoneNumber);
  return tmy.post("/user_activation_codes/" + activationCode + "/sms_invite", req.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.PartnerWeb.V1.Requests.PostUserActivationCodeSmsInvite"
  })
    .then(rh.checkStatus)
}

export function getRoutines() {
  return tmy.get("/routines", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.PartnerWeb.V1.Responses.GetRoutines", responses.GetRoutines))
    .then((resp) => {
      return resp.getRoutinesList();
    })
}
